<template>
  <div v-if="inDelivery()" :class="[show && top ? 'top' : '']">
    <v-card
      v-if="show"
      class="stops-card pa-0 relative rounded-circle"
      :style="top ? '' : 'margin-top: -40px'"
    >
      <v-card-text class="pa-0 fill-height" :class="getCssClass()">
        <ProgressArc
          v-if="stops > 0 && stops <= TRACKING_STATE_THRESHOLD_MED"
          :size="120"
          :stroke-width="4"
          :percent="1 - this.stops / TRACKING_STATE_THRESHOLD_MED"
        />
        <main
          v-if="this.getTrackingState === TRACKING_DELIVERY_STATE_CLOSE"
          class="fill-height d-flex justify-center align-center flex-column bg-white rounded-circle"
        >
          <div class="px-3">
            <div
              class="font-weight-bold"
              v-if="this.getMinutesLeft.from && this.getMinutesLeft.to"
            >
              {{ this.getMinutesLeft.from }} - {{ this.getMinutesLeft.to }}
              {{ this.getMinutesLeft.format }}
            </div>
            <span class="text-footnote text-pre-line" v-if="stops > 0">
              {{ displayStops }} {{ remainingStopsLabel }}
            </span>
          </div>
        </main>
        <main
          v-if="
            this.getTrackingState === TRACKING_DELIVERY_STATE_LAST &&
            stops === 1
          "
          class="pa-5 fill-height d-flex justify-center align-center bg-white rounded-circle"
        >
          <h2 class="text-pre-line">{{ $t('stops.last') }}</h2>
        </main>
        <main
          v-if="
            (this.getTrackingState === TRACKING_DELIVERY_STATE_LAST &&
              stops === 0) ||
            this.getTrackingState === TRACKING_STATE_DELIVERED
          "
          class="last-stop-or-delivered"
          style="margin: -1px"
        >
          <LottieAnimation :file="animationUrl"></LottieAnimation>
        </main>
        <main
          v-if="
            this.getTrackingState === TRACKING_DELIVERY_STATE_NEAR ||
            this.getTrackingState === TRACKING_STATE_SHADOWED
          "
          class="fill-height d-flex justify-center align-center flex-column"
        >
          <div
            class="text-body-2"
            v-if="this.getTrackingState === TRACKING_STATE_SHADOWED"
          >
            {{ $t('overview.details.lessStops') }}
          </div>
          <div class="text-h3 leading-none">
            {{ displayStops }}
          </div>
          <div class="text-body-2 stops-label">
            {{ remainingStopsLabel }}
            {{ showPositionExplanation ? '*' : '' }}
          </div>
        </main>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_DELIVERY_STATE_NEAR,
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_SHADOWED,
  TRACKING_STATE_THRESHOLD_BLURRY
} from '@/store/constants';
import ProgressArc from '@/components/ui/ProgressArc';
import LottieAnimation from '@/components/ui/LottieAnimation';
import { getSeasonOrEventTimeFrameName } from '@/services/seasonAndEventHelper';
import { configGetter } from '@/config/configGetter';

export default {
  name: 'StopsComponent',
  components: { ProgressArc, LottieAnimation },
  props: {
    stops: {
      type: Number,
      default: null
    },
    top: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      TRACKING_DELIVERY_STATE_CLOSE,
      TRACKING_DELIVERY_STATE_LAST,
      TRACKING_DELIVERY_STATE_NEAR,
      TRACKING_STATE_THRESHOLD_BLURRY,
      TRACKING_STATE_DELIVERED,
      TRACKING_STATE_SHADOWED
    };
  },
  computed: {
    ...mapState(['trackingData', 'isBelowMobileBreakpoint', 'themeId']),
    ...mapGetters([
      'getTrackingState',
      'showPositionExplanation',
      'getThemeId',
      'getMinutesLeft'
    ]),
    animationUrl() {
      const seasonOrEventAnimationUrls = {
        autumn: '/animations/autumn.json',
        halloween: '/animations/halloween.json',
        winter: '/animations/gls_anim_christmas_02.json',
        newYear: '/animations/gls_anim_christmas_02.json', // no animation for new year yet, so use winter animation
        winter2: '/animations/gls_winter.json',
        snowboard: '/animations/snowboard.json',
        christmas: '/animations/Christmas01.json'
      };

      const seasonOrEventName = getSeasonOrEventTimeFrameName();
      if (this.getThemeId?.startsWith('gls') && seasonOrEventName) {
        const animationUrl = seasonOrEventAnimationUrls[seasonOrEventName];
        if (animationUrl) return animationUrl;
      }

      return this.getTrackingState === TRACKING_STATE_DELIVERED
        ? `/themes/${this.getThemeId}/animations/delivered.json`
        : `/themes/${this.getThemeId}/animations/delivering.json`;
    },
    remainingStopsLabel() {
      return (
        (this.displayStops > 1 && this.getSlavicPluralDeclination()) ||
        this.$tc('stops.moreThanLast', this.displayStops)
      );
    },
    TRACKING_STATE_THRESHOLD_MED() {
      return this.trackingData.maxStopsToShowDriverLocation;
    },
    show() {
      return (
        this.stops !== null &&
        this.inDelivery() &&
        !configGetter(this.$rttI18n.tenantId, this.themeId)
          .disableStopsComponent
      );
    },
    displayStops() {
      return this.getTrackingState === TRACKING_STATE_SHADOWED
        ? this.getStopsStep(this.stops)
        : this.stops;
    }
  },
  methods: {
    getSlavicPluralDeclination() {
      const lastDigit = this.displayStops % 10;
      return this.$rttI18n.tcOrEmpty(
        `stops.moreThanLast${
          [0, 1, 5, 6, 7, 8, 9].includes(lastDigit) ||
          [12, 13, 14].includes(this.displayStops)
            ? 'FiveToTen'
            : 'TwoToFour'
        }`
      );
    },
    inDelivery() {
      return (
        this.getTrackingState === TRACKING_DELIVERY_STATE_NEAR ||
        this.getTrackingState === TRACKING_DELIVERY_STATE_CLOSE ||
        this.getTrackingState === TRACKING_DELIVERY_STATE_LAST ||
        this.getTrackingState === TRACKING_STATE_DELIVERED ||
        this.getTrackingState === TRACKING_STATE_SHADOWED
      );
    },
    getStopsStep(stops) {
      const steps = [5, 10, 20, 30, 50, 70, 90];
      for (const step of steps) {
        if (stops < step) {
          return step;
        }
      }
      return stops;
    },
    getCssClass() {
      return this.getTrackingState.toLowerCase();
    }
  }
};
</script>

<style scoped>
.top {
  height: 80px;
}
.stops-card {
  text-align: center;
  background-color: transparent;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1) !important;
  width: 120px;
  height: 120px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0%);
  z-index: 3;
}

.stops-card .v-card-text {
  color: black;
  font-size: 12px;
  font-weight: bold;
}
.stops-label {
  width: 75%;
}
.shadowed {
  background-color: rgb(var(--v-theme-stopsContainer));
  color: var(--stopsContainer-color) !important;
}
.near {
  background-color: rgb(var(--v-theme-stopsContainer));
  color: var(--stopsContainer-color) !important;
}
</style>
