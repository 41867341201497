<template>
  <div>
    <v-btn
      color="info"
      class="spin"
      variant="text"
      size="small"
      :disabled="this.lastUpdatedInSeconds < 2"
      v-on:click.prevent="refreshTrackingData"
    >
      <img
        alt="Icon delivered"
        class="update_icon"
        :src="publicPath(`/themes/${this.getThemeId}/icon_update.svg`)"
      />
      <span class="text-link">{{
        $t('overview.details.updated.description')
      }}</span>
    </v-btn>

    <div class="text-footnote align-center">
      <span v-if="lastUpdatedInMinutes === 0">
        {{ $t('overview.details.updated.justNow') }}
      </span>
      <span v-if="lastUpdatedInMinutes === 1">
        {{ $t('overview.details.updated.oneMinute') }}
      </span>
      <span v-if="lastUpdatedInMinutes > 1">
        {{
          $t('overview.details.updated.minutes', {
            minutes: lastUpdatedInMinutes
          })
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import publicPath from '@/util/publicPath';
import { UPDATE_TRACKING_EVENT } from '@/store/constants.js';

let timer;

export default {
  name: 'LastUpdatedComponent',
  props: {
    updateTimestamp: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      lastUpdatedInMinutes: 0,
      lastUpdatedInSeconds: 0,
      refreshInterval: 2001
    };
  },
  computed: {
    ...mapGetters(['getThemeId'])
  },
  methods: {
    publicPath,
    refreshTrackingData() {
      this.lastUpdatedInSeconds = 0;
      this.$mitt.emit(UPDATE_TRACKING_EVENT);
    },
    setLastUpdated() {
      this.lastUpdatedInSeconds = (Date.now() - this.updateTimestamp) / 1000;
      this.lastUpdatedInMinutes = Math.floor(
        this.lastUpdatedInSeconds / 60,
        10
      );
    },
    updateInterval() {
      timer = setInterval(this.setLastUpdated, this.refreshInterval);
    }
  },
  mounted() {
    this.updateInterval();
  },
  beforeUnmount() {
    clearInterval(timer);
  }
};
</script>

<style scoped>
.spin img {
  transition: transform 0.3s ease-in-out;
}
.spin:hover img {
  transform: rotate(-360deg);
  transition: transform 0.7s ease-in-out;
}
.spin:disabled {
  opacity: 0.5;
}
.update_icon {
  margin-right: 0.5em;
}
</style>
